(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.WIDE_DESKTOP_WIDTH = 1440;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$INFO_MENU = $('.header-info-menu > ul');
  window.$SERVICE_MENU = $('.header-menu-container > .wrapper > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/iPad|iPhone|iPod|android|webOS|Windows Phone|BlackBerry|IEMobile|Opera Mini/i);
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW
    .on('resize', function() {
      window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.height();
    })
    .trigger('resize');

  /**
   * @return boolean
   */
  window.IS_WIDE_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= WIDE_DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return ( WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Variables
  var slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: false
      };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '\\+7 \\([0-6,9]\\d\\d\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__'
    }
  });
  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');


  $DOCUMENT
    .ready(function() {
      $DOCUMENT
        // Move header menu items
        .trigger('moveHeaderMenuItems')
        // Header service menu events
        .trigger('headerServiceMenuEvents')
        // Header slide bg image
        .trigger('initHeaderSlideBg');
      $WINDOW.on('resize', function() {
        $DOCUMENT
          .trigger('moveHeaderMenuItems')
          .trigger('headerServiceMenuEvents')
          .trigger('initHeaderSlideBg');
      });

      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', function() {
          if( this.checked ) {
            $BODY.addClass('menu-is-opened');
          } else {
            $BODY.removeClass('menu-is-opened');
          }
        })
        .prop('checked', false)
        .prop('disabled', false)
        .trigger('change');

      // Portfolio gallery
      // -----------------
      $('.portfolio-page-gallery-container .portfolio-page-gallery').slick($.extend({}, slickSettings, {
        fade: true,
        dots: true,
        draggable: true,
        slide: '.portfolio-gallery-item',
        swipeToSlide: true,
        appendArrows: '.portfolio-page-gallery-container .portfolio-gallery-arrows',
        appendDots: '.portfolio-page-gallery-container .portfolio-gallery-pager',
        customPaging: function(slider, index) { return $('<div style="background-image:url(\''.concat(slider.$slides.eq(index).data('pre'), '\')"/>')); }
      }));

      // Response rotator
      // ----------------
      $('.response-rotator').each(function() {
        var $rotator = $(this).children('.response-list');

        $rotator.slick($.extend({}, slickSettings, {
          autoplay: true,
          adaptiveHeight: IS_MOBILE_WIDTH(),
          draggable: true,
          appendArrows: $(this).children('.response-rotator-arrows'),
          slide: '.response-item',
          slidesToScroll: 1,
          slidesToShow: 3,
          swipeToSlide: true,
          responsive: [
            {
              breakpoint: WIDE_DESKTOP_WIDTH,
              settings: {
                autoplay: true,
                adaptiveHeight: false,
                slidesToShow: 2
              }
            },
            {
              breakpoint: LANDSCAPE_MOBILE_WIDTH,
              settings: {
                autoplay: false,
                adaptiveHeight: true,
                slidesToShow: 1
              }
            }
          ]
        }));
      });
    })

    // Move header menu items
    // ----------------------
    .on('moveHeaderMenuItems', function() {
      if( IS_LANDSCAPE_TABLET_WIDTH() ) {
        if( !$INFO_MENU.hasClass('is-moved') ) {
          $INFO_MENU.addClass('is-moved');

          $SERVICE_MENU.find('ul').removeAttr('style');
          $SERVICE_MENU.find('li').removeClass('is-hovered');

          $INFO_MENU.children('.main-item').prependTo($SERVICE_MENU);
          $INFO_MENU.children('.info-item').appendTo($SERVICE_MENU);
        }
      }
      else {
        if( $INFO_MENU.hasClass('is-moved') ) {
          $INFO_MENU.removeClass('is-moved');

          $SERVICE_MENU.children('.main-item').prependTo($INFO_MENU);
          $SERVICE_MENU.children('.info-item').appendTo($INFO_MENU);

          $SERVICE_MENU.find('ul').removeAttr('style');
          $SERVICE_MENU.find('li').removeClass('is-hovered');
        }
      }
    })

    // Header service menu events
    // ---------------------------------
    .on('headerServiceMenuEvents', function() {
      if( IS_LANDSCAPE_TABLET_WIDTH() ) {
        if( !$SERVICE_MENU.hasClass('is-mobile-events') ) {
          $SERVICE_MENU.addClass('is-mobile-events');

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-menu-container > .wrapper > ul').length) ) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu-container > .wrapper > ul li > div > div > a, .header-menu-container > .wrapper > ul li > div > div > span, .header-menu-container > .wrapper > ul li > div > .dropmarker', function(e) {
              e.preventDefault();

              var $self = $(this),
                  $parent = $self.closest('li'),
                  $elementsToClose = $parent.siblings('.with-dropdown:not(.icn-menu-service-group-null)').add($parent.siblings('.icn-menu-service-group-null').find('> ul > li.with-dropdown')),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              if( $parent.closest('ul').closest('.icn-menu-service-group-null').length )
                $elementsToClose = $elementsToClose.add($parent.closest('ul').closest('.icn-menu-service-group-null').siblings('.with-dropdown'));

              $elementsToClose.removeClass('is-hovered').children('ul').slideUp(300);

              if( isWithDropdown ) {
                if( isOnHover ) {
                  if( $self.prop("tagName").toUpperCase() === 'A' )
                    location.href = $self.prop('href');
                  else
                    $parent.removeClass('is-hovered').children('ul').slideUp(300);
                } else {
                  $parent.addClass('is-hovered').children('ul').slideDown(300);
                }
              } else {
                if( $self.prop("tagName").toUpperCase() === 'A' )
                  location.href = $self.prop('href');
              }
            });
        }
      }
      else {
        if( $SERVICE_MENU.hasClass('is-mobile-events') ) {
          $SERVICE_MENU.removeClass('is-mobile-events');

          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');
        }

        // Fix header submenu popup position
        // ---------------------------------
        var headerMenuWidth = $SERVICE_MENU.outerWidth();

        $SERVICE_MENU.children().each(function() {
          var $item = $(this),
              $submenu = $(this).children('ul');

          if( $submenu.length ) {
            $submenu.css('max-width', headerMenuWidth);

            if( $submenu.outerWidth() + $item.position().left > headerMenuWidth )
              $submenu.css('left', 0 - ($submenu.outerWidth() + $item.position().left - headerMenuWidth));
          }
        });
      }
    })

    // Header slide bg image
    // ---------------------
    .on('initHeaderSlideBg', function() {
      var $slide = $('.header-slide-block > div');

      $slide.css('background-image', "url('".concat((IS_MOBILE_WIDTH() ? $slide.data('imagePre') : $slide.data('image')), "')"));
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', function(e) {
      e.preventDefault();

      var $lnk = $(this);

      if( !$lnk.hasClass('active') ) {
        var $destination = $($lnk.parents('.js-tabs').data('tabDest'));

        $lnk.addClass('active').parent().siblings().children('a').removeClass('active');
        $destination.children().addClass('dn').eq($lnk.parent().index()).removeClass('dn');
      }
    })

    // Price groupped quick links
    // --------------------------
    .on('click.priceGrouppedQuickLinks', '.price-groupped-table-container .quick-link-list a', function(e) {
      e.preventDefault();

      var $link = $(this);

      if( !$link.parent().hasClass('active') ) {
        $link
          .parent().addClass('active')
          .siblings().removeClass('active')
          .first().parent().parent().addClass('selected')
          .parent().find('.price-table-container .price-table').addClass('selected')
          .find($link.attr('href').substr($link.attr('href').lastIndexOf('#')))
          .parent().addClass('active')
          .siblings().removeClass('active');
      }
    })

    // Show-hide block
    // ---------------
    .on('click.jsShowHide', '.js-show-hide-handle', function(e) {
      e.preventDefault();

      var $self = $(this),
          $destination = $($self.data('dest'));

      if( $self.hasClass('active') ) {
        $self.removeClass('active').text($self.data('text'));
        $destination.addClass('dn');
      }
      else {
        $self.addClass('active').text($self.data('textActive'));
        $destination.removeClass('dn');
      }
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: function(widget, current) {
      if( current.contentType === 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH() ) {
        current.$content.find('.js-autofocus-inp').trigger("focus");
      }
    }
  });
})();
